<template>
  <div v-loading="loading">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">回答詳細・承否認</h3>
        </div>
      </div>
      <div class="card-body" v-if="form_detail.length">
        <el-table class="mb-5" :data="form_detail" style="width: 100%">
          <el-table-column prop="form_name" min-width="130" label="フォーム名">
            <template slot-scope="scope">
                <span v-if="scope.row.delete_flag === form_base_delete_flag.ACTIVE">
                    <router-link :to="{ name: 'edit-form', params: { form_id:  scope.row.form_id}}">
                        {{ scope.row.form_name }}
                    </router-link>
                 </span>
                <span v-else-if="scope.row.delete_flag === form_base_delete_flag.DELETE">
                    {{ scope.row.form_name }}
                </span>
              </template>
          </el-table-column>
          <el-table-column prop="form_url" min-width="130" label="フォームURL">
            <template slot-scope="scope">
                <span>
                <a target="_blank" :href="scope.row.user_url">
                   {{ scope.row.form_url }}
                </a>
                 </span>
              </template>
          </el-table-column>
<!--            phase 2-->
          <el-table-column prop="parent_form" min-width="130" label="親フォーム">
               <div v-if="formParent">
                    <span v-if="formParent.delete_flag === form_base_delete_flag.ACTIVE">
                        <router-link :to="{ name: 'edit-form', params: { form_id:  formParent.form_id }}">
                            {{ formParent.form_name }}
                        </router-link>
                    </span>
                   <span v-else-if="formParent.delete_flag === form_base_delete_flag.DELETE">
                       {{ formParent.form_name }}
                   </span>
               </div>
          </el-table-column>
          <el-table-column prop="current_authority" min-width="130" label="現担当権限">
              {{ currentRole }}
          </el-table-column>
<!--            phase 2-->
          <el-table-column prop="form_items_count" min-width="130" label="項目数">
          </el-table-column>
<!--            phase 2-->
          <el-table-column prop="child_form" min-width="130" label="子フォーム">
               <template slot-scope="scope">
                <span>
                <router-link :to="{ name: 'form-base-list', query: { form_child:  scope.row.form_id ? scope.row.form_id : '' }}">
                   {{ total_form_child }}
                </router-link>
                 </span>
              </template>
          </el-table-column>
<!--            phase 2-->
          <el-table-column prop="form_status" min-width="130" label="ステータス">
            <template slot-scope="scope">
              <span v-if="parseInt(scope.row.form_status) === constants.FORM_BASE_STATUS[0].value" class="statusDTD">{{constants.FORM_BASE_STATUS[0].label}}</span>
                <span v-else-if="parseInt(scope.row.form_status) === constants.FORM_BASE_STATUS[1].value" class="statusDKK">{{constants.FORM_BASE_STATUS[1].label}}</span>
            </template>
          </el-table-column>
        </el-table>
<!--          phase 2-->
          <div class="row mb-3">
            <i @click="changeToggleTableStep()" v-if="toggleTableStep"
               class="fas fa-angle-down pr-2 text-size-27 angle-down"></i>
            <i @click="changeToggleTableStep()" v-else
               class="fas fa-angle-up pr-2 text-size-27 angle-up"></i>
              <h3 @click="changeToggleTableStep()" class="card-label">承認プロセスログ</h3>
          </div>
        <el-collapse-transition>
          <div v-show="toggleTableStep">
            <div class="row mb-3">
              <div class="col-md-2 col-6">
                <span>承認プロセス段階</span>
              </div>
              <div class="col-md-3 col-6">{{ currentStep.totalApprovalStep }} / ステップ{{ currentStep.currentApprovalStep }}</div>
            </div>
            <div id="data-table-list">
              <el-table class="mb-5 table-search" :data="currentStep.currentApprovalList"
                        style="width: 100%" @sort-change="sortTable">
                <el-table-column prop="step" min-width="130" class="column-table-2" label="ステップ" sortable="false">
                  <template slot-scope="scope">
                    <span>{{ scope.row.step }}</span>
                  </template>
                </el-table-column>
                  <el-table-column prop="approval_datetime" min-width="130" class="column-table-2" label="日付" sortable="false">
                      <template slot-scope="scope">
                          <span>{{ scope.row.approval_datetime }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column prop="approval_status" min-width="150" class="column-table-2" label="アクション" sortable="false">
                      <template slot-scope="scope">
                              <span v-if="parseInt(scope.row.approval_status) === constants.STATUS_ANSWER.APPROVAL.KEY" class="statusDTD">{{constants.STATUS_ANSWER.APPROVAL.VALUE}}</span>
                              <span v-else-if="parseInt(scope.row.approval_status) === constants.STATUS_ANSWER.DENIAL.KEY" class="statusDKK">{{constants.STATUS_ANSWER.DENIAL.VALUE}}</span>
                              <span v-else-if="parseInt(scope.row.approval_status) === constants.STATUS_ANSWER.HOLD.KEY" class="statusDKK">{{constants.STATUS_ANSWER.HOLD.VALUE}}</span>
                              <span v-else-if="parseInt(scope.row.approval_status) === constants.STATUS_ANSWER.REVIEW.KEY" class="statusOD">{{constants.STATUS_ANSWER.REVIEW.VALUE}}</span>
                      </template>
                  </el-table-column>
                <el-table-column prop="role_name" min-width="130" class="column-table-2" label="担当ロール" sortable="false">
                  <template slot-scope="scope">
                    {{  scope.row.role_name }}
                  </template>
                </el-table-column>

                <el-table-column prop="mail" min-width="130" class="column-table-2" label="処理者" sortable="false">
                  <template slot-scope="scope">
                    {{  scope.row.mail  }}
                  </template>
                </el-table-column>

                <el-table-column prop="wariate_user_id" min-width="130" class="column-table-2" label="割り当て先 " sortable="false">
                  <template slot-scope="scope">
                    {{ scope.row.mail_wariate }}
                  </template>
                </el-table-column>

                <el-table-column prop="comment" min-width="130" class="column-table-2" label="コメント" sortable="false">
                  <template slot-scope="scope">
                    <pre class="pre-comment">{{ scope.row.comment }}</pre>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-collapse-transition>
<!--          phase 2-->
        <div v-for="(item,index) in item_groups.form" :key="index">
          <div v-if="item.display_group" class="d-flex">
              <i @click="item.display = !item.display" v-if="item.display"
                class="fas fa-angle-down pr-2 text-size-27 angle-down"></i>
              <i @click="item.display = !item.display " v-else
                 class="fas fa-angle-up pr-2 text-size-27 angle-up"></i>
              <h3 @click="item.display = !item.display" class="card-label" ref="he">{{item.group_name}}</h3>
            </div>

            <el-collapse-transition>
              <div v-show="item.display">
                  <div v-for="(valueGroup,keyGroup) in item" :key="keyGroup">
                  <div v-for="(value,key) in valueGroup">
                      <div v-if="typeof value.child_group_name != 'undefined' && value.display_group" class="group-form"
                           :class="value.child_group_name != 'un_group'?'group-form-middle':''">
                          <div class="title-group-form" v-if="value.child_group_name != 'un_group'">{{value.child_group_name}}</div>
                          <div v-for="(field,k) in value.fields" :key="k">
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.TEXT && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col v-if="field.form_answer_value" :span="8" class="value" >
                                          <el-input :disabled="checkInputStatus(field)" class="bg-gray-item"
                                                    v-model="field.value"></el-input>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                      <el-col v-else-if="field.display_content || field.special_processing == type_input.HIDE_CUSTOMER" :span="8" class="value" >
                                          <el-input :disabled="checkInputStatus(field)" class="bg-gray-item"
                                                    v-model="field.display_content"></el-input>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                      <el-col v-else-if="field.special_processing == type_input.PASSWORD ||
                      field.special_processing == type_input.EMAIL" :span="8" class="value" >
                                          <el-input :disabled="checkInputStatus(field)" class="bg-gray-item" :type="field.special_processing == type_input.PASSWORD ? 'password' : 'text'"
                                                    placeholder="*************"
                                                    v-model="field.value"></el-input>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                      <el-col v-else :span="8" class="value" >
                                          <el-input :disabled="checkInputStatus(field)" class="bg-gray-item"
                                                    v-model="field.value" ></el-input>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                  </el-row>
                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="8" class="value" >
                                          <el-input v-model="v.value" :disabled="checkInputStatus(field)" class="bg-gray-item"></el-input>
                                      </el-col>
                                  </el-row>

                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.TEXT_MULTIPLE_LINE && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <el-input :disabled="checkInputStatus(field)" type="textarea" class="bg-gray-item" rows="3" v-model="field.value"></el-input>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                  </el-row>
                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="8" class="value" >
                                          <el-input v-model="v.value" type="textarea" :disabled="checkInputStatus(field)" class="bg-gray-item"></el-input>
                                      </el-col>
                                  </el-row>
                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.SELECTION && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <el-select
                                              :disabled="checkInputStatus(field)"
                                              v-if="field.selections.split(',').length > 0" style="width: 100%" class="w--10 bg-gray-item"
                                              placeholder="" v-model=field.value>
                                              <el-option
                                                  v-for="(valueItem, index) in field.selections.split(',')"
                                                  :key="index"
                                                  :label="valueItem"
                                                  :value="String(index)">
                                              </el-option>
                                          </el-select>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                  </el-row>

                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="8" class="value" >
                                          <el-select
                                              :disabled="checkInputStatus(field)"
                                              v-if="field.selections.split(',').length > 0" style="width: 100%" class="w--10 bg-gray-item"
                                              placeholder="" v-model="v.value">
                                              <el-option
                                                  v-for="(valueItem, index) in field.selections.split(',')"
                                                  :key="index"
                                                  :label="valueItem"
                                                  :value="String(index)">
                                              </el-option>
                                          </el-select>
                                      </el-col>
                                  </el-row>
                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.SELECTION_MULTIPLE && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <el-select
                                              :disabled="checkInputStatus(field)"
                                              multiple
                                              v-if="field.selections.split(',').length > 0"
                                              style="width: 100%" class="w--10 bg-gray-item"
                                              placeholder="" v-model="field.value">
                                              <el-option
                                                  v-for="(valueItem, index) in field.selections.split(',')"
                                                  :key="index"
                                                  :label="valueItem"
                                                  :value="index">
                                              </el-option>
                                          </el-select>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                  </el-row>

                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="8" class="value" >
                                          <el-select
                                              multiple
                                              :disabled="checkInputStatus(field)"
                                              v-if="field.selections.split(',').length > 0" style="width: 100%" class="w--10 bg-gray-item"
                                              placeholder="" v-model="v.value">
                                              <el-option
                                                  v-for="(valueItem, index) in field.selections.split(',')"
                                                  :key="index"
                                                  :label="valueItem"
                                                  :value="index">
                                              </el-option>
                                          </el-select>
                                      </el-col>
                                  </el-row>

                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.FILE && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="18" class="value">
                                          <input :disabled="checkInputStatus(field)" style="display: none"
                                                 :id="'file-upload'+field.item_id" type="file"
                                                 @change="uploadFile($event,field)">
                                          <label :for="'file-upload'+field.item_id"
                                                 class="file-drag file-upload-btn btn btn-primary button-upload-file"
                                                 :class="checkInputStatus(field)?'disabled-upload-image':''">
                                              ファイル選択
                                          </label>
                                          <div class="d-flex align-items-center box-file">
                                              <div>
                                                  <a v-if="field.value" :href="field.value.url_download_file">
                                                      {{field.value.file_name_before }}
                                                  </a>
                                              </div>

                                              <div>
                                                  <a href="javascript:void(0)" class="view-version"
                                                     @click.prevent="updateVersion(field.file_id,field.item_id,field.label_name)">
                                                      {{ $t("VIEW_VERSION_FILE") }}
                                                  </a>
                                              </div>
                                          </div>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">
                                              {{ errorField['field_' + field.item_id][0] }}
                                          </div>
                                      </el-col>
                                  </el-row>

                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="18" class="value" >
                                          <input :disabled="checkInputStatus(v)" style="display: none" :id="'file-upload'+v.item_id" type="file" @change="uploadFile($event,field)">
                                          <label :for="'file-upload'+field.item_id" class="file-drag file-upload-btn btn btn-primary button-upload-file" :class="checkInputStatus(field)?'disabled-upload-image':''">
                                              ファイル選択
                                          </label>
                                          <div class="d-flex align-items-center box-file">
                                          <div>
                                              <a v-if="v.value" :href="v.value.url_download_file">
                                                  {{v.value.file_name_before}}
                                              </a>
                                          </div>

                                          <div>
                                              <a href="javascript:void(0)" class="view-version" @click.prevent="updateVersion(v.value.file_id,v.item_id,v.label_name)">
                                                  {{$t("VIEW_VERSION_FILE")}}
                                              </a>
                                          </div>
                                          </div>

                                      </el-col>
                                  </el-row>
                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.LABEL && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="18" class="value" >
                                          <div class="label-field-preview"  v-html="field.display_content"></div>
                                      </el-col>
                                  </el-row>

                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name"></el-col>
                                      <el-col :span="18" class="value" >
                                          <div class="label-field-preview"  v-html="field.display_content"></div>
                                      </el-col>
                                  </el-row>

                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.DATETIME && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                          <label v-if="showLableRequired(field)" class="text-danger">*</label>
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <el-date-picker :disabled="checkInputStatus(field)" type="datetime" v-model="field.value" class="bg-gray-item" rows="3" ></el-date-picker>
                                          <div v-if="errorField['field_'+field.item_id]" class="error-message">{{errorField['field_'+field.item_id][0]}}</div>
                                      </el-col>
                                  </el-row>
                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">
                                      </el-col>
                                      <el-col  :span="8" class="value" >
                                          <el-date-picker type="datetime" v-model="v.value" :disabled="checkInputStatus(field)" class="bg-gray-item"></el-date-picker>
                                      </el-col>
                                  </el-row>

                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.FILE_DOWNLOAD && field.display">
                                  <el-row class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <a :href="field.url_download_file">{{getFileName(field.item_file_path) }}</a>
                                      </el-col>
                                  </el-row>

                                  <el-row
                                      v-if="field.childFields.length > 0"
                                      v-for="(v,i) in field.childFields" :key="i"
                                      class="item-answer">
                                      <el-col :span="5" class="label_name">{{field.label_name}}
                                      </el-col>
                                      <el-col :span="8" class="value" >
                                          <a :href="field.url_download_file">{{getFileName(field.item_file_path) }}</a>
                                      </el-col>
                                  </el-row>

                              </div>
                              <div class="m-0 mb-4 transition-box" v-if="field.input_method == fieldType.CALCULATION">
                                <el-row class="item-answer">
                                  <el-col :span="5" class="label_name">{{field.label_name}}
                                  </el-col>
                                  <el-col :span="8" class="value" >
                                    <el-input disabled class="bg-gray-item" rows="3" v-model="field.value"></el-input>
<!--                                    <div>{{field.previewCalculation}}</div>-->
                                  </el-col>
                                </el-row>
                              </div>
                          </div>
                      </div>
                  </div>
           </div>
           </div>
           </el-collapse-transition>
        </div>
        <el-row>
          <el-col :span="5" class="label_name"
            ><h3 class="card-label" ref="he">コメント</h3></el-col
          >
          <el-col :span="18" class="value textarea-form"
            ><el-input
              v-model="comment"
              :rows="6"
              prop="comment"
              type="textarea"
              class="textarea"
            ></el-input
          ></el-col>
        </el-row>
        <div class="card mt-5"></div>
        <el-row class="text-center button-update-status" >
<!--            comment out-->
<!--            <el-button :disabled="answerDetail.disableButton" :class="answerDetail.disableButton ? 'disable-buttoon' : ''"
                type="primary"
                @click.prevent="handleProcess(constants.HANDLE_PROCESS.UPDATE_VALUE.KEY)"
                class="btn-submit"
            >{{ constants.HANDLE_PROCESS.UPDATE_VALUE.VALUE }}</el-button
            >
          <el-button :disabled="answerDetail.disableButton" :class="answerDetail.disableButton ? 'disable-buttoon' : ''"
            type="primary"
            @click.prevent="handleProcess(constants.HANDLE_PROCESS.CONFIRM.KEY)"
            class="btn-submit"
            >{{ constants.HANDLE_PROCESS.CONFIRM.VALUE }}</el-button
          >
          <el-button :disabled="answerDetail.disableButton" :class="answerDetail.disableButton ? 'disable-buttoon' : ''"
            type="primary"
            @click.prevent="handleProcess(constants.HANDLE_PROCESS.REJECT.KEY)"
            class="btn-submit"
            >{{ constants.HANDLE_PROCESS.REJECT.VALUE }}</el-button
          >
          <el-button :disabled="answerDetail.disableButton" :class="answerDetail.disableButton ? 'disable-buttoon' : ''"
            type="primary"
            @click.prevent="handleProcess(constants.HANDLE_PROCESS.SAVE.KEY)"
            class="btn-submit"
            >{{ constants.HANDLE_PROCESS.SAVE.VALUE }}</el-button
          >
          <el-button :disabled="answerDetail.disableButton" :class="answerDetail.disableButton ? 'disable-buttoon' : ''" type="primary" @click.prevent="openPopupChangeRole()" class="btn-submit">{{constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.VALUE }}</el-button>-->

            <el-button :disabled="answerDetail.user.delete_flag == 1" :class="answerDetail.user.delete_flag == 1 ? 'disable-buttoon' : ''"  type="primary" @click.prevent="handleProcess(constants.HANDLE_PROCESS.UPDATE_VALUE.KEY)" class="btn-submit">{{ constants.HANDLE_PROCESS.UPDATE_VALUE.VALUE }}</el-button>
            <el-button :disabled="answerDetail.user.delete_flag == 1" :class="answerDetail.user.delete_flag == 1 ? 'disable-buttoon' : ''"  type="primary" @click.prevent="handleProcess(constants.HANDLE_PROCESS.CONFIRM.KEY)" class="btn-submit">{{ constants.HANDLE_PROCESS.CONFIRM.VALUE }}</el-button>
            <el-button :disabled="answerDetail.user.delete_flag == 1" :class="answerDetail.user.delete_flag == 1 ? 'disable-buttoon' : ''"  type="primary" @click.prevent="handleProcess(constants.HANDLE_PROCESS.REJECT.KEY)" class="btn-submit">{{ constants.HANDLE_PROCESS.REJECT.VALUE }}</el-button>
            <el-button :disabled="answerDetail.user.delete_flag == 1" :class="answerDetail.user.delete_flag == 1 ? 'disable-buttoon' : ''"  type="primary" @click.prevent="handleProcess(constants.HANDLE_PROCESS.SAVE.KEY)" class="btn-submit">{{ constants.HANDLE_PROCESS.SAVE.VALUE }}</el-button>
            <el-button :disabled="answerDetail.user.delete_flag == 1" :class="answerDetail.user.delete_flag == 1 ? 'disable-buttoon' : ''"  type="primary" @click.prevent="openPopupChangeRole()" class="btn-submit">{{constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.VALUE }}</el-button>
            <el-button v-if="answerDetail.user.delete_flag == 0" type="primary" @click.prevent="openPopupDetele(1)" class="btn-submit">{{constants.HANDLE_PROCESS.SOFT_DELETE.VALUE }}</el-button>
            <el-button  v-if="answerDetail.user.delete_flag == 1" type="primary" @click.prevent="openPopupDetele(3)" class="btn-submit">{{constants.HANDLE_PROCESS.RESTORE.VALUE }}</el-button>
            <el-button :disabled="answerDetail.user.delete_flag == 0" :class="answerDetail.user.delete_flag == 0 ? 'disable-buttoon' : ''" type="primary" @click.prevent="openPopupDetele(2)" class="btn-submit">{{constants.HANDLE_PROCESS.FOCE_DELETE.VALUE }}</el-button>
        </el-row>
      </div>
    </div>
    <b-modal id="confirm" hide-header hide-footer centered>
      <div>
        <div class="modal-header text-center">
          <h3 style="width: 100%">確認</h3>
        </div>
        <div class="modal-body">{{ title }}</div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button class="btn-primary" @click="closePopup('confirm')">
            {{ $t("NO") }}
          </b-button>
          <b-button class="btn-primary" :disabled="disable_confirm" @click.prevent="handleCheck(key)">
            {{ $t("YES") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal id="error-update" hide-header hide-footer centered>
      <div>
        <div class="modal-header text-center">
        </div>
        <div class="modal-body">{{ title }}</div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button class="btn-primary" @click="closePopup('errorUpdate')">
            {{ $t("close_popup_error") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal id="error-required" hide-header hide-footer centered>
      <div>
        <div class="modal-header text-center">
        </div>
        <div class="modal-body">項目に不備があります。ご確認ください。</div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button class="btn-primary" @click="closePopup('errorRequired')">
            {{ $t("close_popup_error") }}
          </b-button>
        </div>
      </div>
    </b-modal>

      <b-modal id="delete_form_answer" hide-header hide-footer centered>
          <div>
              <div class="modal-header text-center">
                  <h3 v-if="checkMethodDetele == 3"  style="width: 100%">確認</h3>
                  <h3 v-else style="width: 100%">確認</h3>
              </div>
              <div v-if="checkMethodDetele == 3" class="modal-body text-center">{{ this.queryParam.answer_id + 'をゴミ箱から戻しますか？' }} </div>
              <div v-else class="modal-body text-center">{{ this.queryParam.answer_id + 'を' + (this.checkMethodDetele == 1 ? '論理削除 ' : '物理削除') + 'しますか？' }}
              </div>
              <div class="modal-footer text-center" style="justify-content: center">
                  <b-button class="btn-primary" @click="closePopup('delete_form_answer')">
                      {{ $t("NO") }}
                  </b-button>
                  <b-button class="btn-primary" @click.prevent="deteleFormAnswer">
                      {{ $t("YES") }}
                  </b-button>
              </div>
          </div>
      </b-modal>

    <!-- modal confirm version file -->
    <b-modal id="answer_file" size="lg" centered :title="label_name">
      <div>
        <el-table class="mb-5" :data="listVersion" style="width: 100%">
          <el-table-column prop="file_edition" label="版" width="40">
          </el-table-column>
          <el-table-column prop="update_datetime" label="日付">
          </el-table-column>
          <el-table-column prop="file_name_after" label="ファイル名変更後">
            <template slot-scope="scope">
              <a :href="scope.row.url_download_file">
                {{scope.row.file_name_after}}
              </a>
            </template>
          </el-table-column>
            <el-table-column prop="file_name_before" label="ファイル名変更前">
                <template slot-scope="scope">
                    <a :href="scope.row.url_download_file">
                        {{scope.row.file_name_before}}
                    </a>
                </template>
            </el-table-column>
        </el-table>
      </div>
      <template #modal-footer>
        <div class="text-center" style="width: 100%">
          <b-button
              variant="primary"
              size="sm"
              @click="closePopup('answer_file')"
          >
            {{ $t("close_popup_error") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="change-role" hide-header hide-footer centered>
      <div>
        <div class="modal-header text-center">
          <h3 style="width: 100%">確認</h3>
        </div>
        <div class="modal-body">
          <p>{{ $t("CHANGE_ROLE", {answer_id: answer_id}) }}</p>
          <div>
            <el-select style="width: 100%" v-model="change_role" placeholder="Select">
              <el-option
                  v-for="item in allRole"
                  :key="item.role_id"
                  :label="item.role_name"
                  :value="item.role_id">
              </el-option>
            </el-select>
            <div v-if="this.errorMessageChangeRole.hasOwnProperty('role') > 0 && this.errorMessageChangeRole.role.length > 0" class="error-message">
              {{this.errorMessageChangeRole.role[0]}}
            </div>
          </div>
        </div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button class="btn-primary" @click="closePopup('change-role')">
            {{ $t("NO") }}
          </b-button>
          <b-button class="btn-primary" @click.prevent="handleCheck(constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY)">
            {{ $t("YES") }}
          </b-button>
        </div>
      </div>
    </b-modal>
      <my-dialog v-on:closeDialog="not_permisson_popup=false" v-on:submit="not_permisson_popup=false;$router.push({name: 'permission-denied'});" v-bind:dialog="not_permisson_popup"
                 :closeOnClickModal="false" width="28%" v-bind:btnOk="$t('DIALOG.BTN_OKIE_PERMISSON_DENIED')" :showCancelButton="false">
          <template slot="header">
              <div class="textwidget text-center">
                  {{ $t('DIALOG.TITLE_PERMISSON_DENIED') }}
              </div>
          </template>
      </my-dialog>
  </div>
</template>
<script>
import * as constants from "@/core/config/constant";
import { mapActions, mapGetters } from "vuex";
import {
    GET_ANSWER_DETAIL,
    ANSWER_HANDLE_STATUS,
    GET_LIST_VERSION,
    CHANGE_ROLE,
    CHANGE_TOGGLE_TABLE_STEP,
    STORE_SOFT_DELETE,
    STORE_FOCE_DELETE,
    RESTORE,
    REMOVE_ERROR_CHANGE_ROLE, UPDATE_VALUE, UPDATE_ANSWER_VALUE,
} from "@/core/services/store/answerDetail.module";

import {
    BUILD_URL_DOWNLOAD_FILE,
    UPLOAD_FILE_USER,
    BYTES_TO_SIZE,
} from "@/core/services/store/file.module";
import {
  GET_ALL_ROLE,
} from "@/core/services/store/role.module";

export default {
  data() {
    return {
      comment: "",
      constants: constants,
      loading: false,
      title: "",
      data: [],
      key: "",
      form_detail: [],
      item_groups: {},
      listVersion: [],
        listAccount:[],
      label_name:'',
      fieldType:constants.FIELDS,
      value_before: '',
      answer_id: '',
      type_input: constants.CHECK_TYPE_INPUT,
      activeNames: ['1'],
        formParent:{},
        total_form_child:0,
        currentRole:'',
        currentStep:[],
      change_role: '',
      tessst: [1,2,5],
      form_base_delete_flag: constants.FORM_BASE_DELETE_FLAG,
      errorField:{},
      checkMethodDetele: "",
      not_permisson_popup: false,
      disable_confirm: false,
    };
  },
  computed: {
    ...mapGetters(["answerDetail","toggleStatus","allRole","errorMessageChangeRole","toggleTableStep"]),
    queryParam() {
      return this.$route.params;
    },
  },
  watch: {
    async queryParam() {
      this.loading = true;
      await this.getAnswerDetail(
        {
          answer_id: this.queryParam.answer_id,
          form_id: this.queryParam.form_id
        }
      );
      this.loading = false;
    },
    'item_groups.form': {
      handler:function (val, oldVal){
        let objectItem = {};
        let calculationFields = [];
        let listField = [];

        let form = this.item_groups.form;

        for (let i = 0;i < form.length;i++){
          let groups = form[i].groups;
          for (let j = 0;j < groups.length;j++){
            let fields = groups[j].fields;
            for (let k = 0;k < fields.length;k++){
              listField.push(fields[k]);
              if (fields[k].input_method === this.fieldType.CALCULATION){
                calculationFields.push(fields[k]);
              }
              if (fields[k].value == parseInt(fields[k].value)){
                objectItem['item_'+fields[k].item_id] = parseInt(fields[k].value);
              }
              if (fields[k].value == parseFloat(fields[k].value)){
                objectItem['item_'+fields[k].item_id] = parseFloat(fields[k].value);

              }

            }
          }
        }
        if (calculationFields.length > 0){
          for (let i = 0; i < calculationFields.length; i++){
            let calculation = [];
            let previewCalculation = [];
            for (let j = 0;j < calculationFields[i].calculation_decode.length;j++){
              let currentObject = calculationFields[i].calculation_decode[j];
              switch (currentObject.type){
                case this.constants.CALCULATION_TYPE.ITEM:
                  // objectItem['item_'+currentObject.id] = 0;
                  calculation.push('objectItem.item_'+currentObject.id);
                  previewCalculation.push(currentObject.label);
                  break;
                case this.constants.CALCULATION_TYPE.CALCULATION:
                  calculation.push(currentObject.label);
                  previewCalculation.push(currentObject.label);
                  break;
                case this.constants.CALCULATION_TYPE.VALUE:
                  calculation.push(currentObject.label);
                  previewCalculation.push(currentObject.label);
                  break;
              }
            }
            let calculationString = calculation.join(' ');
            calculationString = calculationString.replace(/x/gi, '*').replace(/:/g, '/')
            let result = ''
            try {
              result = eval(calculationString);
              if (calculationFields[i].rounding_type == this.constants.CALCULATION_ROUND.DOWN){
                if (parseInt(calculationFields[i].number_decimal) > 0 || calculationFields[i].number_decimal === 0){
                  result = Math.floor(result * Math.pow(10, calculationFields[i].number_decimal)) / Math.pow(10, calculationFields[i].number_decimal);
                }
              }
              if (calculationFields[i].rounding_type == this.constants.CALCULATION_ROUND.UP){
                if (parseInt(calculationFields[i].number_decimal) > 0 || calculationFields[i].number_decimal === 0){
                  result = Math.ceil(result * Math.pow(10, calculationFields[i].number_decimal)) / Math.pow(10, calculationFields[i].number_decimal);
                }
              }
            } catch (error) {
              console.log(error)
            }
            if (isNaN(result)){
              result = '';
            }
            calculationFields[i].value = result;
            calculationFields[i].previewCalculation = previewCalculation.join(' ');
          }
        }

        let that = this;
        let fieldMultipleBranching = listField.filter(function (i){
          if (!i.related_items_decode){
            return false
          }
          if (i.input_method == that.fieldType.SELECTION_MULTIPLE){
            return true;
          }
          return false;
        });
        if (fieldMultipleBranching.length > 0){
          for (let i = 0;i < fieldMultipleBranching.length;i++){
            let field = fieldMultipleBranching[i];
            let compare = false;
            let answerActionCode = Object.values(field.answer_action_decode);
            let choose = Object.values(field.value);
            let options = field.selections.split(',');
            let optionsValues = [];
            for (let i = 0;i < choose.length;i++){
              optionsValues.push(options[choose[i]]);
            }
            // for (let i = 0; i < answerActionCode.length;i++){
            //   if (optionsValues.includes(answerActionCode[i])){
            //     compare = true;
            //     break;
            //   }
            // }
            let related = Object.values(field.related_items_decode);
            // let fieldRelated = listField.filter(function (i){
            //   if (related.includes(i.item_id)){
            //     return true;
            //   }
            //   return false;
            // })
              for (let j = 0;j < listField.length;j++){
                  for (let t = 0; t < related.length; t++) {
                      if (related[t].includes(listField[j].item_id)){
                          let duplicate = answerActionCode[t].filter(item => optionsValues.includes(item));
                          if (listField[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
                              if (duplicate.length > 0){
                                  listField[j].display = true;
                              }else{
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }
                          }
                          if (listField[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
                              if (duplicate.length > 0){
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }else{
                                  listField[j].display = true;
                              }
                          }
                      }
                  }
              }

            // for (let j = 0;j < fieldRelated.length;j++){
            //   if (fieldRelated[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
            //     if (compare){
            //       fieldRelated[j].display = true;
            //     }else{
            //       fieldRelated[j].display = false;
            //       fieldRelated[j].value = '';
            //     }
            //   }
            //   if (fieldRelated[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
            //     if (compare){
            //       fieldRelated[j].display = false;
            //       fieldRelated[j].value = '';
            //     }else{
            //       fieldRelated[j].display = true;
            //     }
            //   }
            // }
          }
        }

        let fieldBranching = listField.filter(function (i){
          if (!i.related_items_decode){
            return false
          }
          if (i.input_method == that.fieldType.SELECTION){
            return true;
          }
          return false;
        });

        if (fieldBranching.length > 0){
          for (let i = 0;i < fieldBranching.length;i++){
            let field = fieldBranching[i];
            let related = Object.values(field.related_items_decode);
            let options = field.selections.split(',');
            let answerActionCode = Object.values(field.answer_action_decode);
            let textChoose = options[field.value];
            // let compare = true;
            // let fieldRelated = listField.filter(function (i){
            //   if (related.includes(i.item_id)){
            //     return true;
            //   }
            //   return false;
            // });
            // if (!answerActionCode.includes(textChoose)){
            //   compare = false;
            // }

              for (let j = 0;j < listField.length;j++){
                  for (let t = 0; t < related.length; t++) {
                      if (related[t].includes(listField[j].item_id)){
                          if (listField[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
                              if (answerActionCode[t].includes(textChoose)){
                                  listField[j].display = true;
                              }else{
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }
                          }
                          if (listField[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
                              if (answerActionCode[t].includes(textChoose)){
                                  listField[j].display = false;
                                  listField[j].value = '';
                              }else{
                                  listField[j].display = true;
                              }
                          }
                      }
                  }
              }

            // for (let j = 0;j < fieldRelated.length;j++){
            //   if (fieldRelated[j].display_start == constants.DISPLAY_START.HIDE_TO_SHOW){
            //     if (compare){
            //       fieldRelated[j].display = true;
            //     }else{
            //       fieldRelated[j].display = false;
            //       fieldRelated[j].value = '';
            //     }
            //   }
            //   if (fieldRelated[j].display_start == constants.DISPLAY_START.SHOW_TO_HIDE){
            //     if (compare){
            //       fieldRelated[j].display = false;
            //       fieldRelated[j].value = '';
            //     }else{
            //       fieldRelated[j].display = true;
            //     }
            //   }
            // }
          }
        }

        for (let i = 0;i < val.length;i++){
          let groups = val[i].groups;
          for (let j = 0;j < groups.length;j++){
            let fields = groups[j].fields;
            let fieldShow = fields.filter(function (i){
              return i.display == true;
            })
            if (fieldShow.length > 0){
              groups[j].display_group = true;
            }else{
              groups[j].display_group = false;
            }
          }
        }
        for (let i = 0;i < val.length;i++){
          let groups = val[i].groups;
          let groupShow = groups.filter(function (i){
            return i.display_group == true;
          })
          if (groupShow.length > 0){
            val[i].display_group = true;
          }else{
            val[i].display_group = false;
          }
        }

      },
      deep: true
    },
  },
  async created() {
    if (this.hasPermissonCheck('answer_flg')){
        this.loading = true;
        await this.getAnswerDetail( {
            answer_id: this.queryParam.answer_id,
            form_id: this.queryParam.form_id
        }).then((response)=>{
            if(response){
                if(response.data.code === constants.STATUS_CODE.HTTP_BAD_REQUEST){
                    this.title = this.$t("HTTP_BAD_REQUEST");
                    this.$bvModal.show("error-update");
                    this.loading = false
                }
            }
        })
        this.loading = false;
        this.form_detail.push(this.answerDetail.info)
        this.item_groups=this.answerDetail
        this.value_before=this.answerDetail.value
        this.answer_id = this.queryParam.answer_id;
        this.formParent=this.answerDetail.form_parent
        this.total_form_child=this.answerDetail.total_form_child
        this.currentRole=this.answerDetail.current_role
        this.currentStep=this.answerDetail.currentStep
    }else {
        this.not_permisson_popup = true
        this.form_detail=[]
    }

    // this.comment = this.answerDetail.user.comment
  },
  methods: {
    ...mapActions([GET_ANSWER_DETAIL,
        ANSWER_HANDLE_STATUS,
        GET_LIST_VERSION,
        BUILD_URL_DOWNLOAD_FILE,
        GET_ALL_ROLE,
        CHANGE_ROLE,
        CHANGE_TOGGLE_TABLE_STEP,
        UPLOAD_FILE_USER,
        BYTES_TO_SIZE,
        STORE_SOFT_DELETE,
        STORE_FOCE_DELETE,
        RESTORE,
        REMOVE_ERROR_CHANGE_ROLE,
        UPDATE_ANSWER_VALUE]),
    sortTable(sortProps) {
        if (sortProps.prop == 'step'){
            let newStepList = this.currentStep.currenApprovalList.sort(function(a, b) {
                if (sortProps.order=='descending'){
                     return   b.step - a.step;
                }else {
                     return   a.step- b.step;
                }
            });
            this.currentStep.currenApprovalList = newStepList
        }else if ([ 'approval_status','role_name','mail','comment'].indexOf(sortProps.prop) >= 0 ){
            let newStepList = this.currentStep.currenApprovalList.sort(function(a, b) {
            if (sortProps.order=='descending'){
                return a[sortProps.prop] === b[sortProps.prop] ? 0 : a[sortProps.prop] > b[sortProps.prop] ? -1 : 1;
            }else {
                return a[sortProps.prop] === b[sortProps.prop] ? 0 : a[sortProps.prop] < b[sortProps.prop] ? -1 : 1;
            }
            });
            this.currentStep.currenApprovalList = newStepList
        }else if (sortProps.prop == 'approval_datetime' ){
             let newStepList = this.currentStep.currenApprovalList.sort(function(a, b) {
                 if (sortProps.order=='descending'){
                     return   new Date(a.step)- new Date(b.step);

                  }else {
                      return   new Date(b.step) - new Date(a.step);
                  }
              });
              this.currentStep.currenApprovalList = newStepList
          }
      },
      handleProcess(key) {
          var answer_id = this.answerDetail.user.answer_id;
          switch (key) {
              case constants.HANDLE_PROCESS.CONFIRM.KEY:
                  this.title = this.$t("CONFIRM" , {answer_id: answer_id});
                  this.key = constants.HANDLE_PROCESS.CONFIRM.KEY;
                  break;
              case constants.HANDLE_PROCESS.REJECT.KEY:
                  this.title = this.$t("REJECT" , {answer_id: answer_id});
                  this.key = constants.HANDLE_PROCESS.REJECT.KEY;
                  break;
              case constants.HANDLE_PROCESS.SAVE.KEY:
                  this.title = this.$t("SAVE" , {answer_id: answer_id});
                  this.key = constants.HANDLE_PROCESS.SAVE.KEY;
                  break;
              case constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY:
                  this.title = this.$t("CHANGE_ROLE" , {answer_id: answer_id});
                  this.key = constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY;
                  break;
              case constants.HANDLE_PROCESS.UPDATE_VALUE.KEY:
                  this.title = this.$t("UPDATE_VALUE" , {answer_id: answer_id});
                  this.key = constants.HANDLE_PROCESS.UPDATE_VALUE.KEY;
                  break;
              default:
                  break;
          }
          this.$bvModal.show("confirm");
          this.disable_confirm = false;
      },
    closePopup(key) {
      switch(key){
        case 'errorUpdate':
          this.$bvModal.hide("error-update");
          break;
        case 'confirm':
          this.$bvModal.hide("confirm");
          break;
        case 'answer_file':
          this.$bvModal.hide("answer_file");
          break;
        case 'change-role':
          this.$bvModal.hide("change-role");
          break;
        case 'delete_form_answer':
          this.$bvModal.hide("delete_form_answer");
          break;
        case 'errorRequired':
          this.$bvModal.hide("error-required");
          break;
        default:
          break;
      }

    },
    async handleCheck(key) {
      this.disable_confirm = true;
      var answer_id = this.answerDetail.user.answer_id;
      var email = this.answerDetail.user.unique_key_value;
      let answerValue = {};
      this.errorField = {};
      for (const groupFormBase of this.item_groups.form){
        for (const groups of groupFormBase.groups){
          for (const fields of groups.fields){
            let valueFiled = fields.value;
            if (fields.input_method == this.fieldType.DATETIME){
              valueFiled = this.convertDateTime(valueFiled);
            }
            fields.key_value = 'field_'+fields.item_id;
            answerValue['field_'+fields.item_id] = valueFiled;
            if (fields.childFields.length > 0){
              let valueChild = {};
              for (let i = 0;i < fields.childFields.length; i++){
                let valueChildFiled = fields.childFields[i].value;
                if (fields.childFields[i].input_method == this.fieldType.DATETIME){
                  valueChildFiled = this.convertDateTime(valueChildFiled);
                }
                valueChild['value_'+i] = valueChildFiled;
              }
              answerValue['field_child_'+fields.item_id] = valueChild;
            }
          }
        }
      }
      switch (key) {
        case constants.HANDLE_PROCESS.CONFIRM.KEY:
          this.answerHandleStatus({
            answer_id: answer_id,
            email: email,
            status: constants.HANDLE_PROCESS.CONFIRM.KEY,
            comment: this.comment,
            answer_value: answerValue,
            form_value:this.item_groups.form,
          }).then((response) => {
            this.handleRepsonse(response)
          })
          break;
        case constants.HANDLE_PROCESS.REJECT.KEY:
          this.answerHandleStatus({
            answer_id: answer_id,
            email: email,
            status: constants.HANDLE_PROCESS.REJECT.KEY,
            comment: this.comment,
            answer_value: answerValue,
            form_value:this.item_groups.form,
          }).then((response) => {
            this.handleRepsonse(response)
          });
          break;
        case constants.HANDLE_PROCESS.SAVE.KEY:
          this.answerHandleStatus({
            answer_id: answer_id,
            email: email,
            status: constants.HANDLE_PROCESS.SAVE.KEY,
            comment: this.comment,
            answer_value: answerValue,
            form_value:this.item_groups.form,
          }).then((response) => {
            this.handleRepsonse(response)
          });
          break;
        case constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY:
          this.changeRole({
            answer_id: answer_id,
            email: email,
            status: constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY,
            comment: this.comment,
            role: this.change_role,
            answer_value: answerValue,
            form_value:this.item_groups.form,
          }).then(async (response) => {
            if (response.data.code == constants.STATUS_CODE.SUCCESS) {
                await this.getAnswerDetail( {
                    answer_id: this.queryParam.answer_id,
                    form_id: this.queryParam.form_id
                });
                this.currentStep = this.answerDetail.currentStep
                // console.log(this.answerDetail);
              this.notifySuccess(this.$t("UPDATE_SUCCESS"));
              this.closePopup('change-role');
            }else if(response.data.code == constants.STATUS_CODE.PERMISSION){
                this.closePopup('change-role');
                this.title = response.data.message;
                this.loading = false;
                this.$bvModal.show("error-update");
            }
          });
          break;
        case constants.HANDLE_PROCESS.UPDATE_VALUE.KEY:
            this.updateAnswerValue({
                answer_id: answer_id,
                answer_value: answerValue,
                type:'update_value',
                form_value:this.item_groups.form,
            }).then((response) => {
                this.handleRepsonse(response)
            })
          break;
        default:
          break;
      }
    },
    handleRepsonse(response) {
      if (response.data.code == constants.STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("UPDATE_SUCCESS"));
        this.closePopup('confirm');
        let self = this;
        setTimeout(function () {
          self.$router.push({
            name: "form-answer-list",
          });
        }, 500);
      } else if (response.data.code == constants.STATUS_CODE.BAD_REQUEST) {
          this.errorField = response.data.data;
          this.$bvModal.hide("confirm");
          // console.log(this.errorField);
          this.$bvModal.show("error-required");
      } else {
        this.title = response.data.message;
        this.$bvModal.show("error-update");
        this.$bvModal.hide("confirm");
        this.loading = false
      }
    },
  updateVersion(file_id,item_id,label_name){
    this.label_name = label_name
    this.getListVersion({
      answer_id: this.queryParam.answer_id ,
      item_id: item_id,
      form_id: this.queryParam.form_id,
    })
    .then((response)=>{
        if(response.data.code == constants.STATUS_CODE.SUCCESS){
          this.listVersion = response.data.data
          this.$bvModal.show("answer_file");
        }
    });
  },
  showItem(index) {
        this.toggleStatus[index] = !this.toggleStatus[index]
      },
  async openPopupChangeRole(){
    this.change_role = '';
    this.loading = true;
    await this.getAllRole({
        form_id: this.queryParam.form_id
    });
    this.removeErrorChangeRole();
    this.loading = false;
    this.$bvModal.show("change-role");
  },
      openPopupDetele( isSoftDetele) {
        this.checkMethodDetele = isSoftDetele;
        this.$bvModal.show("delete_form_answer");
      },
      showLableRequired(field){
          if (field.item_check_relation) {
              let arrCheck = [];
              field.item_check_relation.map(formItemDetail => {
                  arrCheck.push(formItemDetail.check_method);
              })
              if (arrCheck.includes(1)) {
                  return true
              }
          }
          return false
      },
      checkInputStatus(field){
          if (field.special_processing == constants.CHECK_TYPE_INPUT.EMAIL) {
              return false
          }
          if (field.item_role === constants.ITEM_ROLE_KEY.USER_ADMIN || field.item_role === constants.ITEM_ROLE_KEY.ADMIN || field.special_processing == 4) {
              return false;
          } else {
              return true;
          }
      },
      async uploadFile(e,field){
          let key = 'field_'+field.item_id;
          if (e.target.files.length){
              let size = await this.bytesToSize(e.target.files[0].size);
              if (size <= 100){
                  var files = e.target.files || e.dataTransfer.files;
                  const dataPush = new FormData()
                  dataPush.append("form_id", field.form_id);
                  dataPush.append("file", files[0]);
                  dataPush.append("item_id", field.item_id);
                  dataPush.append("answer_id", this.queryParam.answer_id);
                  const update = await this.uploadFileUser(dataPush);
                  if (update.code == constants.STATUS_CODE.BAD_REQUEST){
                      let obj = {[key]:update.data.file}
                      this.errorField = {...obj};
                  }else{
                      this.value_before[field.item_id] = update.data;
                      field.value = update.data;
                  }
              }else{
                  let obj = {[key]:"ERROR"}
                  this.errorField = {...obj};
              }
          }
          e.target.files = null;
      },
      deteleFormAnswer() {
          if (this.checkMethodDetele == 1) {
              let respon = this.storeSoftDetele({answer_id: this.queryParam.answer_id})
                  .then((response) => {
                      this.notifySuccess('成功を削除')
                      this.getDetail()
                  }).catch(e => {
                      this.notifyError(e)
                  });
          } else if (this.checkMethodDetele == 2) {
              this.storeFoceDetele({answer_id: this.queryParam.answer_id})
                  .then((response) => {
                      this.notifySuccess('成功を削除')
                      setTimeout(()=>{
                          this.$router.push({name: "form-answer-list"})
                      }, 200);
                      return;
                  }).catch(e => {
                  this.notifyError(e)
              });
          } else if (this.checkMethodDetele == 3){
              this.restore({answer_id: this.queryParam.answer_id})
                  .then((response) => {
                  this.notifySuccess('元に戻しました。')
                  this.getDetail()
              }).catch(e => {
                  this.notifyError(e)
              });
          }

          this.closePopup('delete_form_answer')

      },
      getDetail() {
          this.getAnswerDetail({
              answer_id: this.queryParam.answer_id,
              form_id: this.queryParam.form_id
          }).then((response) => {
              if (response) {
                  if (response.data.code === constants.STATUS_CODE.HTTP_BAD_REQUEST) {
                      this.title = this.$t("HTTP_BAD_REQUEST");
                      this.$bvModal.show("error-update");
                      this.loading = false
                  }
              }
          })
      },
      getFileName($filePath){
          if ($filePath) {
              let explode = $filePath.split("/");
              return explode[explode.length - 1]
          }
          return '';
      },
      confirmUpdateValue(){
          this.title = this.$t("CHANGE_ROLE" , {answer_id: answer_id});
          this.key = constants.HANDLE_PROCESS.CHANGE_OF_CHRAGE.KEY;
          this.$bvModal.show("confirm");
      },
      convertDateTime(time){
          let timeFormat = '';
          if (time){
              let datetime = new Date(time);
              if (datetime){
                  let m = datetime.getMonth() + 1;
                  let d = datetime.getDate();
                  let y = datetime.getFullYear();
                  let hours = datetime.getHours();
                  let minutes = datetime.getMinutes();
                  let seconds = datetime.getSeconds();
                  timeFormat = y+'-'+m+'-'+d+' '+hours+':'+minutes+':'+seconds;
              }
          }
          return timeFormat;
      },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 640px) and (min-width: 320px) {
  .btn-submit {
    padding: 4px 0px;
    background: #009ef7;
    top: 50px;
    width: 93px;
    height: 30px;
    border-radius: 5px;
    margin: 10px;
  }
  .textarea-form.value.el-col.el-col-18 {
    width: 100%;
  }
  .value.el-col.el-col-8 {
    right: 0px;
    margin-top: 10px;
    width: 100%;
  }
  h3.card-label {
    margin-top: 40px;
    font-size: 18px;
    font-weight: 700;
    width: max-content;
}
a.view-version {
    float: left;
    width: 100%;
     margin-left:0px !important;
}
.item-answer.el-row {
    display: block !important;
    padding-bottom:20px !important;

}
.label_name.el-col.el-col-5 {
    width: 100%;
    margin-top: 20px;

}
.angle-down , .angle-up{
  margin-top: 44px;
}
}
// end mobie
.text-center.button-update-status.el-row {
    display: block;
    margin-top: 30px;
}
.textarea {
  background: #f5f8fa;
}
.el-row {
  &:last-child {
    margin-bottom: 40px;
  }
  .view-version {
    margin-left:40px
  }
}
.btn-submit {
  padding: 4px 0px;
  background: #009ef7;
  top: 50px;
  width: 93px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
}
.el-col {
  margin-top: 40px;
  border-radius: 4px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.disable-buttoon {
    background-color: grey !important;
    border: none;
}

.el-col {
  &.label_name {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    line-height: 21px;
    font-weight: 500!important;
    font-size: 14px;
    color: #606266;
     margin-top: 20px;
    /* identical to box height */
  }
  &.value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
     margin-top: 20px;
    /* identical to box height */
    color: #000000;
  }
  .button-update-status{
    margin-top: 20px;
  }
.item-answer.el-row {
    display: block !important;
    padding-bottom:20px !important;
}


}
i.fas.fa-angle-down.pr-2.text-size-27 ,h3.card-label {
    cursor: pointer !important;
}
.button-upload-file{
  float: left;
  margin-right: 10px;
}
.item-answer .label_name{
  padding-right: 15px;
}
</style>
<style>
.modal .modal-header{
  display: block;
  text-align: center;
  padding: 13px;
}
.modal .modal-header .close{
  position: absolute;
  top: 24px;
  right: 15px;
}
.group-form{
    position: relative;
}
.modal-content{
    border-radius: 15px;
    border: 2px solid #000000;
}
.box-file {
    min-height: 38px;
}
.box-file >div {
    width: 400px;
}
</style>
